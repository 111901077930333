import { addMonths, endOfMonth, startOfDay, startOfMonth, subMonths } from "date-fns";
import { useState } from "react";

import { type CalendarNavigator, type DateRange } from "./types";

interface MonthRangeProps {
  startDate: Date;
}

export function useMonthRange(props: MonthRangeProps): CalendarNavigator {
  const { startDate } = props;

  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: startOfMonth(startDate),
    endDate: endOfMonth(startDate),
  });

  function onPrevious() {
    const newFromDate = startOfDay(startOfMonth(subMonths(dateRange.startDate, 1)));
    const newToDate = endOfMonth(newFromDate);
    setDateRange({
      startDate: newFromDate,
      endDate: newToDate,
    });
  }

  function onNext() {
    const newFromDate = startOfDay(startOfMonth(addMonths(dateRange.startDate, 1)));
    const newToDate = endOfMonth(newFromDate);
    setDateRange({
      startDate: newFromDate,
      endDate: newToDate,
    });
  }

  return {
    dateRange,
    onPrevious,
    onNext,
  };
}
