import { Text } from "@clipboard-health/ui-react";
import { Box, Stack } from "@mui/material";
import { format, isToday } from "date-fns";
import { type ReactNode } from "react";

interface CalendarCellItemProps {
  date: Date;
  children: ReactNode;
  isDisabled: boolean;
}

export function CalendarCellItem(props: CalendarCellItemProps) {
  const { date, children, isDisabled } = props;
  const dateIsToday = isToday(date);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "auto",
        "&:active": {
          backgroundColor: (theme) => (isDisabled ? undefined : theme.palette.action.hover),
        },
        paddingTop: 0.5,
        paddingBottom: 1,
      }}
    >
      <Stack
        spacing={{
          xs: 0.25,
          sm: 0.5,
          md: 1,
          lg: 2,
        }}
        sx={{
          width: "100%",
        }}
      >
        <Box display="flex" justifyContent="center">
          <Text
            textAlign="center"
            variant="body2"
            sx={(theme) => ({
              ...(isDisabled && { color: theme.palette.grey[500] }),
              width: "24px",
              height: "24px",
              lineHeight: "24px",
              borderRadius: "100%",
              backgroundColor: dateIsToday ? theme.palette.primary.light : undefined,
              color: dateIsToday ? theme.palette.primary.contrastText : undefined,
            })}
          >
            {format(date, "d")}
          </Text>
        </Box>
        {children}
      </Stack>
    </Box>
  );
}
