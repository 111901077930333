import { Calendar, type DateRange } from "../Calendar";
import { type CalendarOptions } from "../Calendar/Calendar";
import { AvailabilityCalendarCell } from "./AvailabilityCalenderCell";

export interface AvailabilityCalendarProps {
  availableDates?: Date[];
  blockedDates?: Date[];
  isLoading?: boolean;
  dateRange: DateRange;
  onNext: () => void;
  onPrevious: () => void;
  options?: CalendarOptions;
  onDateSelect: (props: {
    id: string;
    value: Date;
    isSelected: boolean;
    isDisabled: boolean;
  }) => void;
}

export function AvailabilityCalendar(props: AvailabilityCalendarProps) {
  const {
    availableDates = [],
    blockedDates = [],
    isLoading = false,
    onDateSelect,
    options,
    dateRange,
    onPrevious,
    onNext,
  } = props;

  return (
    <Calendar
      isLoading={isLoading}
      dateRange={dateRange}
      options={options}
      selectedDates={availableDates}
      disabledDates={blockedDates}
      DateCell={({ isSelected, isDisabled, value }) => {
        const cellDate = value;

        return (
          <AvailabilityCalendarCell
            date={cellDate}
            isSelected={isSelected}
            isDisabled={isDisabled}
            isLoading={isLoading}
          />
        );
      }}
      onPrevious={onPrevious}
      onNext={onNext}
      onDateSelect={onDateSelect}
    />
  );
}
