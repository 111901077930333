import { isDefined } from "@clipboard-health/util-ts";
import { Box, LinearProgress } from "@mui/material";
import { isAfter, isBefore, isSameDay, startOfDay } from "date-fns";
import { type ComponentType } from "react";

import { CellGrid, TypographyCell } from "../CellGrid";
import { CalendarHeader } from "./CalendarHeader";
import { type CalendarDateCellProps, type CalendarSelectEvent, type DateRange } from "./types";
import { getCalendarLabels, getCalendarRows } from "./utils";
import { formatDateRange } from "./utils/formatDateRange";
import { getIdByDate } from "./utils/getIdByDate";

export interface CalendarOptions {
  headerIsVisible?: boolean;
  headerTitle?: string;
  minDate?: Date;
  maxDate?: Date;
}

interface CalendarProps {
  dateRange: DateRange;
  onNext: () => void;
  onPrevious: () => void;
  onDateSelect?: (selectedDate: CalendarSelectEvent) => void;
  selectedDates?: Date[];
  disabledDates?: Date[];
  DateCell?: ComponentType<CalendarDateCellProps>;
  isLoading?: boolean;
  options?: CalendarOptions;
}

export function Calendar(props: CalendarProps) {
  const {
    onDateSelect,
    dateRange,
    onNext,
    onPrevious,
    selectedDates = [],
    disabledDates = [],
    DateCell,
    isLoading = false,
    options,
  } = props;

  const { headerIsVisible = true, minDate, maxDate, headerTitle } = options ?? {};

  const currentDateRange = {
    startDate: startOfDay(dateRange.startDate),
    endDate: startOfDay(dateRange.endDate),
  };

  const minMaxDateRange = {
    minDate: minDate ? startOfDay(minDate) : undefined,
    maxDate: maxDate ? startOfDay(maxDate) : undefined,
  };

  const previousDateIsOutOfRange =
    minMaxDateRange.minDate &&
    (isSameDay(currentDateRange.startDate, minMaxDateRange.minDate) ||
      isBefore(currentDateRange.startDate, minMaxDateRange.minDate));
  const nextDateIsOutOfRange =
    minMaxDateRange.maxDate &&
    (isSameDay(currentDateRange.endDate, minMaxDateRange.maxDate) ||
      isAfter(currentDateRange.endDate, minMaxDateRange.maxDate));

  return (
    <Box>
      {headerIsVisible && (
        <Box
          sx={{
            paddingBottom: 0,
          }}
        >
          <CalendarHeader
            title={headerTitle ?? formatDateRange(currentDateRange)}
            previousDateIsOutOfRange={previousDateIsOutOfRange}
            nextDateIsOutOfRange={nextDateIsOutOfRange}
            isLoading={isLoading}
            onPrevious={onPrevious}
            onNext={onNext}
          />
        </Box>
      )}
      <Box sx={{ height: "4px" }}>
        {isLoading && <LinearProgress sx={{ height: "4px" }} title="loading calendar" />}
      </Box>
      <CellGrid
        rows={getCalendarRows(currentDateRange, DateCell ?? TypographyCell, minMaxDateRange)}
        columnLabels={getCalendarLabels()}
        selectedCells={selectedDates.map((date) => ({
          id: getIdByDate(date),
        }))}
        disabledCells={disabledDates.map((date) => ({
          id: getIdByDate(date),
        }))}
        disabled={isLoading}
        onCellSelect={(params) => {
          if (isDefined(params.value) && isDefined(params.title)) {
            onDateSelect?.({
              id: params.id,
              isDisabled: params.isDisabled,
              isSelected: params.isSelected,
              title: params.title,
              value: params.value,
            });
          }
        }}
      />
    </Box>
  );
}
