import { Alert, type AlertColor } from "@mui/material";
import { type ReactNode } from "react";

export interface BannerProps {
  children: ReactNode;
  severity: AlertColor;
  onClose?: () => void;
}

export function Banner(props: BannerProps) {
  const { children, severity, onClose } = props;

  return (
    <Alert
      severity={severity}
      icon={false}
      sx={{
        borderRadius: 0,
        width: "100%",
        "& .MuiAlert-message": { width: "100%", textAlign: "center" },
      }}
      variant="filled"
      onClose={onClose}
    >
      {children}
    </Alert>
  );
}
