import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { IconButton } from "@mui/material";
import { type LocationDescriptor } from "history";
import { useHistory } from "react-router-dom";

interface BackButtonLinkProps {
  defaultBackTo: LocationDescriptor;
  alwaysUseDefaultBackTo?: boolean;
  onClick?: () => void;
}

export function BackButtonLink(props: BackButtonLinkProps) {
  const { defaultBackTo, alwaysUseDefaultBackTo = false, onClick } = props;
  const history = useHistory();
  return (
    <IconButton
      size="large"
      edge="start"
      color="inherit"
      aria-label="Go Back"
      title="Go Back"
      onClick={() => {
        if (alwaysUseDefaultBackTo) {
          history.replace(defaultBackTo);
        } else if (history.length > 1) {
          history.goBack();
        } else {
          history.push(defaultBackTo);
        }

        onClick?.();
      }}
    >
      <ChevronLeftIcon fontSize="large" />
    </IconButton>
  );
}
