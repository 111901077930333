import CircleIcon from "@mui/icons-material/Circle";
import { IconButton } from "@mui/material";

export function InvisibleCta() {
  // FIXME if there is a better way to centre the header title
  return (
    <IconButton disabled size="large" edge="end">
      <CircleIcon fontSize="large" sx={{ visibility: "hidden" }} />
    </IconButton>
  );
}
