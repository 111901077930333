import { Text } from "@clipboard-health/ui-react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Container, IconButton } from "@mui/material";

export interface CalendarHeaderProps {
  onPrevious: () => void;
  onNext: () => void;
  previousDateIsOutOfRange?: boolean;
  nextDateIsOutOfRange?: boolean;
  isLoading?: boolean;
  title: string;
}

export function CalendarHeader(props: CalendarHeaderProps) {
  const {
    onPrevious,
    onNext,
    title,
    previousDateIsOutOfRange,
    nextDateIsOutOfRange,
    isLoading = false,
  } = props;

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
      }}
      maxWidth="md"
    >
      <IconButton
        size="small"
        disabled={previousDateIsOutOfRange}
        aria-label="Previous dates"
        onClick={onPrevious}
      >
        <ArrowBackIcon />
      </IconButton>
      <Box flexGrow={1} position="relative" textAlign="center">
        <Text bold color={isLoading ? "gray" : undefined}>
          {title}
        </Text>
      </Box>
      <IconButton
        size="small"
        disabled={nextDateIsOutOfRange}
        aria-label="Next dates"
        onClick={onNext}
      >
        <ArrowForwardIcon />
      </IconButton>
    </Container>
  );
}
