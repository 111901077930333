import { type ReactNode } from "react";
import { Switch, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

// eslint-disable-next-line no-restricted-imports
import classes from "./Switch.module.scss";

interface RouteTransitionSwitchProps {
  children: ReactNode[];
}

export function RouteTransitionSwitch(props: RouteTransitionSwitchProps) {
  const { children } = props;
  const location = useLocation();
  return (
    <TransitionGroup>
      <CSSTransition
        key={location.pathname}
        /**
         * Be aware that this timeout must be longer than the animation timeouts.
         * In addition, during transition, the app blocks all UI interactions.
         */
        timeout={220}
        classNames={{
          enter: classes.navigationEnter,
          enterActive: classes.navigationEnterActive,
          exit: classes.navigationExit,
          exitActive: classes.navigationExitActive,
        }}
      >
        <Switch location={location}>{children}</Switch>
      </CSSTransition>
    </TransitionGroup>
  );
}
