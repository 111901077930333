import { Title } from "@clipboard-health/ui-react";
import { AppBar, Container, Toolbar } from "@mui/material";
import { type ReactElement, type ReactNode } from "react";

import { InvisibleCta } from "./components/InvisibleCta";

/**
 * The app bar height is set to match the Ionic app bar height.
 * This breaks with MUI standards, which specifies 8px multipliers.
 * TODO: Replace with 40 or 48px once we have all page headers converted to MUI.
 */
export const APP_BAR_HEIGHT = "44px";

interface AppBarHeaderProps {
  title: string;
  leftCta?: ReactNode;
  rightCta?: ReactNode;
}

export function AppBarHeader(props: AppBarHeaderProps): ReactElement {
  const { title, leftCta, rightCta } = props;
  return (
    <AppBar
      position="static"
      sx={{
        zIndex: (theme) => theme.zIndex.appBar,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: {
              /**
               * The MUI theme has a high specificity for this height for each mediaQuery
               * Using media query to override locally.
               */
              xs: APP_BAR_HEIGHT,
            },
            height: APP_BAR_HEIGHT,
          }}
        >
          {leftCta ?? <InvisibleCta />}
          <Title noWrap variant="h4" component="h1" textAlign="center" color="inherit">
            {title}
          </Title>
          {rightCta ?? <InvisibleCta />}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
