import { InternalLink, Span } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Alert, type AlertColor } from "@mui/material";
import { type LinkProps } from "react-router-dom";

export interface BannerLinkProps {
  children: string;
  title?: string;
  to: LinkProps["to"];
  severity: AlertColor;
  onClose?: () => void;
  onClick?: () => void;
}

export function BannerLink(props: BannerLinkProps) {
  const { children, title, to, severity, onClose, onClick } = props;

  return (
    <Alert
      severity={severity}
      icon={false}
      sx={{
        paddingLeft: 0,
        paddingY: 0,
        borderRadius: 0,
        width: "100%",
        "& .MuiAlert-action": { paddingX: 0 },
        "& .MuiAlert-message": { padding: 0, width: "100%" },
      }}
      variant="filled"
      onClose={onClose}
    >
      <InternalLink
        to={to}
        underline="none"
        color="inherit"
        sx={{
          /**
           * This is a CSS hack to center the banner content with relation to the close button action
           * 38px is hard-coded based on action container width when the close button exists
           * 16px is hard-coded based on the margin of the action container when the close button does not exist
           */
          paddingLeft: isDefined(onClose) ? "38px" : "16px",
          paddingY: 1,
          height: "100%",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          "&:hover": {
            color: "inherit",
          },
        }}
        onClick={onClick}
      >
        {title && (
          <Span display="block">
            <b>{title}</b>
          </Span>
        )}
        {children}
      </InternalLink>
    </Alert>
  );
}
