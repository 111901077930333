import { format } from "date-fns";

import { type DateRange } from "../types";

interface FormatDateRangeOptions {
  dateFormat?: string;
}

export function formatDateRange(dateRange: DateRange, options: FormatDateRangeOptions = {}) {
  const { startDate, endDate } = dateRange;
  const { dateFormat = "MMM d" } = options;

  return `${format(startDate, dateFormat)} - ${format(endDate, dateFormat)}`;
}
