import { isDefined } from "@clipboard-health/util-ts";
import {
  ButtonBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { type CellGridItemProps, type CellId, type ColumnLabels, type Row } from "./types";

interface Props<T = string> {
  columnLabels?: ColumnLabels[];
  onCellSelect?: (cell: CellGridItemProps<T>) => void;
  rows: Array<Row<T>>;
  selectedCells?: CellId[];
  disabledCells?: CellId[];
  disabled?: boolean;
}

export function CellGrid<T>(props: Props<T>) {
  const {
    rows,
    onCellSelect,
    columnLabels,
    selectedCells = [],
    disabledCells = [],
    disabled = false,
  } = props;

  return (
    <TableContainer
      sx={{
        display: "flex",
      }}
    >
      <Table>
        {columnLabels ? (
          <TableHead>
            <TableRow>
              {columnLabels.map(({ id, title }) => (
                <TableCell
                  key={id}
                  sx={{
                    textAlign: "center",
                    borderBottom: "none",
                    paddingY: 1,
                  }}
                >
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        ) : null}

        <TableBody>
          {rows.map((row) => {
            const { id: rowId, columns } = row;

            return (
              <TableRow key={rowId}>
                {columns.map((column) => {
                  const { title, label, CellGridItem, id: columnId, value } = column;

                  const isCellSelected = selectedCells.some((cell) => cell.id === columnId);

                  const isCellDisabled = disabledCells.some((cell) => cell.id === columnId);

                  return (
                    <TableCell
                      key={columnId}
                      sx={{
                        padding: 0,
                        borderBottom: "none",
                        width: `calc(100% / ${rows[0]?.columns.length ?? 1})`,
                      }}
                    >
                      {isDefined(value) && isDefined(title) ? (
                        <ButtonBase
                          disableRipple
                          disableTouchRipple
                          sx={{
                            height: "100%",
                            width: "100%",
                          }}
                          aria-label={label}
                          aria-pressed={isCellSelected}
                          aria-disabled={disabled}
                          disabled={disabled}
                          component="div"
                          onClick={() => {
                            if (disabled) {
                              return;
                            }

                            onCellSelect?.({
                              id: columnId,
                              title,
                              value,
                              isSelected: isCellSelected,
                              isDisabled: isCellDisabled,
                            });
                          }}
                        >
                          {isDefined(CellGridItem) && (
                            <CellGridItem
                              id={columnId}
                              title={title}
                              value={value}
                              isSelected={isCellSelected}
                              isDisabled={isCellDisabled}
                            />
                          )}
                        </ButtonBase>
                      ) : null}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
