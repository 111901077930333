import { Text } from "@clipboard-health/ui-react";
import CircleIcon from "@mui/icons-material/Circle";
import { Box, Stack } from "@mui/material";
import { format, isBefore, isToday, startOfDay } from "date-fns";

interface AvailabilityCalendarCellProps {
  date: Date;
  isSelected: boolean;
  isDisabled: boolean;
  isLoading: boolean;
}

export function AvailabilityCalendarCell(props: AvailabilityCalendarCellProps) {
  const { date, isSelected, isDisabled, isLoading } = props;
  const isPastDate = isBefore(date, startOfDay(new Date()));

  return (
    <Stack
      sx={(theme) => ({
        width: "100%",
        height: "60px",
        border: `1px solid ${theme.palette.grey[100]}`,
        ...(isToday(date) && {
          border: `1px solid ${theme.palette.primary.main}`,
        }),
        ...((isLoading || isPastDate) && {
          backgroundColor: theme.palette.grey[50],
        }),
      })}
    >
      <Box
        sx={{ paddingY: 0.5, textAlign: "center" }}
        aria-selected={isSelected}
        aria-label={format(date, "yyyy-MM-dd")}
      >
        <Text variant="body2">{format(date, "d")}</Text>
        {(isDisabled || isSelected) && (
          <CircleIcon
            color={isDisabled ? "disabled" : "success"}
            sx={{ height: 11, width: 11 }}
            aria-label={isSelected ? "Date marked as available" : "Date marked as unavailable"}
          />
        )}
      </Box>
    </Stack>
  );
}
