export function getCalendarLabels() {
  return [
    { id: "column-label-1", title: "Su" },
    { id: "column-label-2", title: "Mo" },
    { id: "column-label-3", title: "Tu" },
    { id: "column-label-4", title: "We" },
    { id: "column-label-5", title: "Th" },
    { id: "column-label-6", title: "Fr" },
    { id: "column-label-7", title: "Sa" },
  ];
}
