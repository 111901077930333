import { formatDate } from "@clipboard-health/date-time";
import { isDefined } from "@clipboard-health/util-ts";
import { eachDayOfInterval, endOfWeek, format, isAfter, isBefore, startOfWeek } from "date-fns";
import { type ComponentType } from "react";

import { type Row } from "../../CellGrid/types";
import { type CalendarDateCellProps, type DateRange } from "../types";
import { getIdByDate } from "./getIdByDate";

export function getCalendarRows(
  dateRange: DateRange,
  DateCell: ComponentType<CalendarDateCellProps>,
  minMaxRange?: { minDate?: Date; maxDate?: Date }
): Array<Row<Date>> {
  const { startDate, endDate } = dateRange;
  const firstDayOfWeek = startOfWeek(startDate);
  const lastDayOfWeek = endOfWeek(endDate);

  const dates = eachDayOfInterval({
    start: firstDayOfWeek,
    end: lastDayOfWeek,
  });

  const rows: Array<Row<Date>> = [];
  let rowId = 0;
  const DAYS_IN_WEEK = 7;

  for (let index = 0; index < dates.length; index += DAYS_IN_WEEK) {
    const currentRowDates = dates.slice(index, index + DAYS_IN_WEEK);

    const columns = currentRowDates.map((date) => {
      const isOutOfMinRange =
        (minMaxRange?.minDate && isBefore(date, minMaxRange?.minDate)) ?? false;
      const isOutOfMaxRange =
        (minMaxRange?.maxDate && isAfter(date, minMaxRange?.maxDate)) ?? false;

      if (isOutOfMinRange || isOutOfMaxRange) {
        // Empty slots for dates outside the min max range
        return {
          id: getIdByDate(date),
        };
      }

      if (isBefore(date, startDate) || isAfter(date, endDate)) {
        // Empty slots for dates outside the interval
        return {
          id: getIdByDate(date),
        };
      }

      return {
        id: getIdByDate(date),
        title: format(date, "dd"),
        label: formatDate(date),
        value: date,
        CellGridItem: DateCell,
      };
    });

    // Skip rows that have no valid dates within the interval
    const hasValidDates = columns.some((col) => isDefined(col.value));
    if (hasValidDates) {
      const row = {
        id: `row-${rowId}`,
        columns,
      };

      rows.push(row);
      rowId += 1;
    }
  }

  return rows;
}
