import { Text } from "@clipboard-health/ui-react";
import { Stack, type SxProps, type Theme } from "@mui/material";
import { type ComponentType } from "react";

interface CardAlertProps {
  label: string;
  IconComponent?: ComponentType;
  sx?: SxProps<Theme>;
  severity?: "error" | "info" | "success";
}

export function CardAlert(props: CardAlertProps) {
  const { label, IconComponent, sx, severity = "error" } = props;
  return (
    <Stack
      spacing={0.5}
      paddingX={1}
      paddingY={0.125}
      border="1px solid"
      borderColor={(theme) => theme.palette[severity].light}
      borderRadius={0.25}
      bgcolor={(theme) => theme.palette[severity].contrastText}
      direction="row"
      role="alert"
      alignItems="center"
      sx={sx}
    >
      {IconComponent && <IconComponent />}
      <Text variant="subtitle2" color={severity}>
        {label}
      </Text>
    </Stack>
  );
}
